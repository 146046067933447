<template>
  <div class="buyerCenter">
      <a-breadcrumb separator=">" class="breadcrumb">
      <a-breadcrumb-item>
        <a @click.prevent="$router.push('/index')">首页</a>
      </a-breadcrumb-item>
      <a-breadcrumb-item>
        {{$route.meta.title}}
      </a-breadcrumb-item>
    </a-breadcrumb>
    <div class="mainbody">
      <Each/>
      <router-view />
    </div>
  </div>
</template>

<script>
import Each from "./components/Each.vue";
export default {
  components: {
    Each
  },
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.buyerCenter {
  max-width: 1440px;
  min-width: 990px;
  @media screen and (max-width: 1440px){
    padding: 0 15px 60px 15px;
  }
  margin: auto;
  padding: 0 0 60px 0;
  .mainbody {
    border: 1px solid #e8e8e8;
    display: flex;
  }
  .breadcrumb{
    width: 100%;
    font-size: 14px;
  }
}
</style>
