<template>
<div class="menu-list">
 <h2>关于我们</h2>
  <a-menu  :selectedKeys="selectedKeys" mode="inline" theme="light">
    <a-menu-item v-for="(item) in list" :key="item.route">
        <router-link :to="item.route">
              {{item.title}}
        </router-link>
    </a-menu-item>
  </a-menu>
</div>
</template>

<script>

export default {
    data() {
        return {
            selectedKeys: [],
            list:[
                {title:'91再生交易平台',route:'/about'},
                {title:'关于91再生',route:'/about/about-91'},
                {title:'联系我们',route:'/about/contact'},
            ]
        };
    },
    mounted () {
        this.updateMenu()
        // console.log('this,',this.list)
    },
    watch: {
    '$route' () {
        this.updateMenu()
        }
    },
    methods: {
        handleClick() {
            this.updateMenu()
        },
        updateMenu () {
            const routes = this.$route.matched.concat()
            this.selectedKeys = [ routes.pop().path ]
        }
    }
}
</script>

<style lang="less" scoped>
.menu-list {
    width: 250px;
    background-color: #fff;
    border-right: 1px solid #e8e8e8;
}
/deep/ .ant-menu-item {
    &:nth-child(1) {
        margin-top: 0;
    }
}
/deep/ .ant-menu-item {
    a {
        color: rgba(0, 0, 0, 0.65);
    }
}
/deep/ .ant-menu-item-selected {
    a {
        color: @primary;
    }
}
/deep/ .ant-menu-inline{
    border-right: none;
}
/deep/.ant-menu-item::after{
    position: absolute;
    top: 0;
    left: 0;
    border-left: 3px solid #60a830;
    border-right: none;
}
h2{
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(102, 102, 102);
}
</style>